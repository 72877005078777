
import firebase from 'firebase';
import Vue from 'vue';
import LoginWrapper from '../LoginWrapper.vue';
import { FormatPhoneNumber } from '../../../services/phone';
import { Notification } from '../../../services/notification';
export default Vue.extend({
  components: {
    LoginWrapper,
  },
  data() {
    return {
      phone: '',
      applicationVerifier: null as any,
    };
  },
  mounted() {
    this.initRecaptcha();
  },
  methods: {
    acceptNumber() {
      let x = this.phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/) as RegExpMatchArray;
      this.phone = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    initRecaptcha() {
      this.applicationVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response: any) => {},
        }
      );
    },
    async login(event: any) {
      event.preventDefault();
      const validatedPhone = FormatPhoneNumber(this.phone);
      if (validatedPhone === null) {
        Notification.methods.send(false, 'Invalid phone number', '');
        return;
      }

      try {
        const result = await firebase
          .auth()
          .signInWithPhoneNumber(validatedPhone, this.applicationVerifier);
        var verificationCode = window.prompt(
          'Please enter the verification ' +
            'code that was sent to your mobile device.'
        );
        const result2 = await result.confirm(verificationCode as string);
        if (result2.user) {
          this.$store.commit('setFirebaseUser', firebase.auth().currentUser);
        }
      } catch (error) {
        console.log(error);
        // Handle Errors here.
      }
      //   if (!result) {
      //     Notification.methods.send(false, 'Invalid Code', '');
      //   } else {
      //     this.$store.commit('setUser', { name: this.name, phone: this.phone });
      //     this.$router.push({ path: '/home' });
      //   }
    },
  },
});
