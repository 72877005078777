
import Vue from 'vue';
import LoginCode from './LoginCode.vue';
import LoginNoPreRegistration from './LoginNoPreRegistration.vue';
import LoginWithPreRegistration from './LoginWithPreRegistration.vue';
import { Notification } from '../../services/notification';
import { FirebaseService } from '../../services/firebase';
import { LoginService } from '../../services/login';
import { CountService } from '../../services/count';
import { Count } from '../../models/count';
import { GetCountSuccess } from '../../models/firebase';
import { FormType } from '../../models/survey';
export default Vue.extend({
  components: {
    LoginCode,
    LoginNoPreRegistration,
    LoginWithPreRegistration,
  },
  data() {
    return {
      countSuccess : null as GetCountSuccess | null,
      code: this.$store.getters.countID,
    };
  },
  watch: {
    loggedIn() {
       if (this.$store.getters.loggedIn) {
         this.$router.push('/home');
       }
    },
  },
  computed: {
      loggedIn() {
        return this.$store.getters.loggedIn;
      },
      prereg(): boolean {
          if (!this.code) {
              return false;
          }
          return !!this.$store.getters.surveyStructure(FormType.VolunteerSignup);
      }
  },
    methods: {
        async codeChange(code: string) {
        const countResult = await CountService.getCount(code);
        if (!countResult.success) {
            Notification.methods.send(false, 'Invalid Code', '');
            return;
        }
        this.countSuccess = countResult; 
        
        const result = await CountService.setCount(code);
        if (!result) {
            Notification.methods.send(false, 'Invalid Code', '');
        }
        this.code = code;
    },
    },
});
