
import Vue from 'vue';
import * as SurveyVue from 'survey-vue';
import { FormType } from '../../../models/survey';
import FormDisplay from '../../FormDisplay.vue';
import { FirebaseService } from '../../../services/firebase';
import { Notification } from '@/services/notification';
import { CountService } from '../../../services/count';
import firebase from 'firebase';
export default Vue.extend({
  mounted() {
    firebase.auth().onAuthStateChanged(() => {
      FirebaseService.listenToRegistration();
    });
  },
  components: {
    FormDisplay,
  },
  computed: {
    structure(): SurveyVue.SurveyModel {
      return this.$store.getters.surveyStructure(FormType.VolunteerSignup);
    },
    // We use the default language for volunteer sign up.
    language(): undefined {
      return undefined;
    },
  },
  methods: {
    async onComplete(survey: SurveyVue.SurveyModel, options: any) {
      const answers = survey.data;
      const result: boolean = await FirebaseService.submitVolunteerRegistration(
        answers
      );
      if (result) {
        firebase.auth().onAuthStateChanged(() => {
          FirebaseService.listenToRegistration();
        });
        // Should be redirected after the listening to our registration doc.
      } else {
        Notification.methods.send(false, 'Problem during registration', '');
      }
    },
  },
});
