
import Vue from 'vue';
import { Notification } from '../../services/notification';
import { FirebaseService } from '../../services/firebase';
import { LoginService } from '../../services/login';
import { CountService } from '../../services/count';
import  LoginWrapper  from './LoginWrapper.vue';
export default Vue.extend({
  name: 'login',
  components: {
    LoginWrapper,
  },
  data() {
    return {
      code: this.$store.getters.countID,
    };
  },
  methods: {
    async login(event: any) {
      event.preventDefault();
      this.$emit('codeChange', this.code);
    },
  },
});
