
import Vue from 'vue';
import { Notification } from '../../services/notification';
import { FirebaseService } from '../../services/firebase';
import { LoginService } from '../../services/login';
import  LoginWrapper  from './LoginWrapper.vue';
export default Vue.extend({
  name: 'noprereg',
  components: {
    LoginWrapper,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      phone: '',
      phoneRules: [
        (v: string) => !!v || 'Phone is required',
        (v: string) => (v && v.length === 10) || 'Phone must be 10 characters',
      ],
    };
  },
  methods: {
    async login(event: any) {
      event.preventDefault();
      const result = await LoginService.methods.preLogin(
        this.code,
        this.name,
        this.phone
      );
      if (!result) {
        Notification.methods.send(false, 'Invalid Code', '');
      } else {
        this.$store.commit('setUser', { name: this.name, phone: this.phone });
        this.$router.push({ path: '/home' });
      }
    },
  },
});
