
import Vue from 'vue'
import LoginWrapper from '../LoginWrapper.vue'
import Survey from './Survey.vue'
export default Vue.extend({
    components: {
        LoginWrapper,
        Survey,
    }
    
})
