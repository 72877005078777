
import firebase from 'firebase';
import Vue from 'vue';
import PhoneLogin from './PreRegistration/PhoneLogin.vue';
import InfoForm from './PreRegistration/InfoForm.vue';
export default Vue.extend({
    components: {
        PhoneLogin,
        InfoForm,
    },
    computed: {
        loggedIn(): boolean {
            return this.$store.getters.firebaseUser !== null;
        },
    }
});
