
import Vue from 'vue';
import * as SurveyVue from 'survey-vue';
const Survey = SurveyVue.Survey as any;
export default Vue.extend({
  props: ['structure', 'language', 'onComplete'],
  components: {
    Survey,
  },
  data() {
    return {
      currentPage: 0,
      finished: false,
    };
  },
  beforeRouteLeave(to, from, next): void {
    if (this.finished) {
      next();
    } else if (this.currentPage == 0) {
      next();
    } else {
      this.survey.prevPage();
      next(false);
    }
  },
  computed: {
    survey(): SurveyVue.SurveyModel {
      const survey = new SurveyVue.Model(this.structure);
      survey.onComplete.add(this.onComplete);
      survey.onCurrentPageChanged.add(this.onPageChanged);
      if (this.language) {
        survey.locale = this.language;
      }
      SurveyVue.StylesManager.applyTheme('orange');
      return survey;
    },
  },
  methods: {
    async onPageChanged(survey: SurveyVue.SurveyModel, options: any): Promise<void> {
      this.currentPage = survey.currentPageNo;
      this.survey = survey;
      // Scroll to top of page. Required for safari.
      scroll(0, 0);
    },
  },
});
